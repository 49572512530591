import { SearchFilter } from "../types/filters";
import { getRequestObject } from "./base";
import { getVehicleTag, serverHeaders } from "./site";

export function getShortFilters(
  siteId: string,
  initialFilters: string[],
  filterParams: any,
  language?: string,
): Promise<SearchFilter[] | null> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/filtersShort/`;

  return getRequestObject<SearchFilter[]>(API, { ...filterParams, lang: language, filters: initialFilters.join(",") });
}

export function getSiteFilters(
  siteId: string,
  query: any,
  fields: string[],
  language?: string,
): Promise<SearchFilter[] | null> {
  const API = `${process.env.SITE_BUILDER_INTERNAL_PATH}/${siteId}/filters`;

  return getRequestObject<SearchFilter[]>(API, { ...query, fields: fields.join(","), lang: language }, serverHeaders, [
    getVehicleTag(siteId),
  ]);
}

"use client";

import cx from "classnames";
import { FC } from "react";
import { ImageData, IPageBlock } from "../../../types/ui";
import BlockImage from "../../common-components/block-image";
import styles from "./styles.module.scss";
import { useIsMobile } from "../../../utils/useIsMobile";
import { ButtonAction } from "../../../types/site";
import StringToHtmlTransformer from "../../common-components/string-to-html-transformer";

interface ServiceItemData {
  title: string;
  text: string;
  image: ImageData;
  button: ButtonAction;
}

interface ServiceData {
  items: ServiceItemData[];
}

const HomeService: FC<IPageBlock> = ({ config, pageContext, siteData }) => {
  const isMobile = useIsMobile(768, pageContext.isSmartphone);
  const serviceInfoData: ServiceData = config;
  const items = serviceInfoData?.items || [];

  return serviceInfoData ? (
    <section
      className={styles.container}
      style={{ gridTemplateColumns: `repeat(${(isMobile ? 1 : items.length) || 1}, 1fr)` }}
    >
      {items.map((item, index) => (
        <div className={styles.itemContainer} key={index}>
          <div
            className={cx(styles.item, {
              [styles.itemSingle]: items.length === 1,
            })}
          >
            {item.image && (
              <BlockImage
                image={item.image}
                alt={item.title}
                className={styles.icon}
                lazyLoading={true}
                mobile={pageContext.isSmartphone}
                siteData={siteData}
              />
            )}
            <h4 title={item.title} className={styles.title}>
              {item.title}
            </h4>
            <div className={styles.text}>
              <StringToHtmlTransformer layout={item?.text || ""} />
            </div>
            {item.button && (
              <a href={item.button.path || ""} title={item.button.title} className={styles.link}>
                {item.button.title}
              </a>
            )}
          </div>
        </div>
      ))}
    </section>
  ) : null;
};

export default HomeService;

"use client";

import { FC, useCallback, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { createAppleWalletSpecials } from "../../../api/wallet";
import { ISpecialCardApi } from "../../../types/service";
import { ButtonAction, ClientSiteData } from "../../../types/site";
import BlockImage from "../../common-components/block-image";
import Button from "../../common-components/button";
import AddToWalletButton, { WalletType } from "../../common-components/add-to-wallet-button/add-to-wallet-button";
import styles from "./special-block-data.module.scss";
import { handleButtonAction } from "../../../utils/ui";
import { formatDate } from "../../../utils/stringFormatter";
import { getButtonActionResult, sendAnalyticsEvent } from "../../../utils/analitics";
import { useIsMobile } from "../../../utils/useIsMobile";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

function getSpecialsValue(data: ISpecialCardApi): string {
  if (data.discountDescription) {
    return `${data.discountValue} ${data.discountDescription}`;
  }
  return data.discountValue || "";
}

function getSpecialsDescription(data: ISpecialCardApi): string {
  if (data.subtitle) {
    return `${data.subtitle} ${data.title}`;
  }

  return data.title || "";
}

export interface ISpecialBlockData {
  item: ISpecialCardApi;
  shouldShowAppleWallet: boolean;
  isSmartphone: boolean;
  siteData: ClientSiteData;
  printOfferText: string;
  translations: Translations;
}

const SpecialBlockData: FC<ISpecialBlockData> = ({ item, siteData, isSmartphone, printOfferText, translations }) => {
  const isMobile = useIsMobile(768, isSmartphone);
  const t_common = getTranslationModule(translations, "common");
  const dealerPhoto = !siteData?.noImagesPhoto ? "" : siteData?.baseUrl + siteData?.noImagesPhoto;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleServiceButton = useCallback(
    (button: ButtonAction) => {
      handleButtonAction(button, {
        name: item?.title + " " + item?.subtitle,
        image: item?.image,
      });
      sendAnalyticsEvent("asc_cta_interaction", {
        element_text: button.title,
        element_title: button.title,
        element_type: "body",
        event_action_result: getButtonActionResult(button),
        link_url: button.path,
      });
    },
    [item?.title, item?.subtitle, item?.image],
  );

  const handleAppleWalletClick = useCallback(() => {
    createAppleWalletSpecials(siteData.id, {
      specialValue: getSpecialsValue(item),
      specialDescription: getSpecialsDescription(item),
      expires: item?.expirationDate,
      description: item?.description,
      shortDescription: item?.description,
      terms: item?.terms,
    }).then((res) => {
      const file = new File([res], "specials.pkpass", { type: "application/vnd.apple.pkpass" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    });
  }, [siteData.id, item]);

  return (
    <section className={styles.container} ref={componentRef}>
      {isMobile && item?.expirationDate && (
        <span className={styles.expires}>{t_common("expires") + " " + formatDate(item?.expirationDate)}</span>
      )}
      <div className={styles.imageContainer}>
        {item?.image && (
          <BlockImage
            image={item?.image}
            siteData={siteData}
            alt={item?.title + " " + item?.subtitle}
            lazyLoading={false}
            mobile={isSmartphone}
            className={styles.image}
          />
        )}
        {!item?.image && dealerPhoto && <img src={dealerPhoto} alt="dealer Photo" />}
      </div>
      <div className={styles.info}>
        {!isMobile && (
          <div className={styles.infoHeader}>
            {item?.expirationDate && (
              <span className={styles.expires}>{t_common("expires") + " " + formatDate(item?.expirationDate)}</span>
            )}
            <button className={styles.print} onClick={handlePrint}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4301_165342)">
                  <path
                    d="M15.0003 5.83335V4.33335C15.0003 3.39993 15.0003 2.93322 14.8187 2.5767C14.6589 2.2631 14.4039 2.00813 14.0903 1.84834C13.7338 1.66669 13.2671 1.66669 12.3337 1.66669H7.66699C6.73357 1.66669 6.26686 1.66669 5.91034 1.84834C5.59674 2.00813 5.34177 2.2631 5.18198 2.5767C5.00033 2.93322 5.00033 3.39993 5.00033 4.33335V5.83335M5.00033 15C4.22535 15 3.83786 15 3.51994 14.9148C2.65721 14.6837 1.98335 14.0098 1.75218 13.1471C1.66699 12.8292 1.66699 12.4417 1.66699 11.6667V9.83335C1.66699 8.43322 1.66699 7.73316 1.93948 7.19838C2.17916 6.72797 2.56161 6.34552 3.03202 6.10584C3.5668 5.83335 4.26686 5.83335 5.66699 5.83335H14.3337C15.7338 5.83335 16.4339 5.83335 16.9686 6.10584C17.439 6.34552 17.8215 6.72797 18.0612 7.19838C18.3337 7.73316 18.3337 8.43322 18.3337 9.83335V11.6667C18.3337 12.4417 18.3337 12.8292 18.2485 13.1471C18.0173 14.0098 17.3434 14.6837 16.4807 14.9148C16.1628 15 15.7753 15 15.0003 15M12.5003 8.75002H15.0003M7.66699 18.3334H12.3337C13.2671 18.3334 13.7338 18.3334 14.0903 18.1517C14.4039 17.9919 14.6589 17.7369 14.8187 17.4233C15.0003 17.0668 15.0003 16.6001 15.0003 15.6667V14.3334C15.0003 13.3999 15.0003 12.9332 14.8187 12.5767C14.6589 12.2631 14.4039 12.0081 14.0903 11.8483C13.7338 11.6667 13.2671 11.6667 12.3337 11.6667H7.66699C6.73357 11.6667 6.26686 11.6667 5.91034 11.8483C5.59674 12.0081 5.34177 12.2631 5.18198 12.5767C5.00033 12.9332 5.00033 13.3999 5.00033 14.3334V15.6667C5.00033 16.6001 5.00033 17.0668 5.18198 17.4233C5.34177 17.7369 5.59674 17.9919 5.91034 18.1517C6.26686 18.3334 6.73357 18.3334 7.66699 18.3334Z"
                    stroke="#007CC2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4301_165342">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className={styles.printText}>{printOfferText}</span>
            </button>
          </div>
        )}
        <div className={styles.mainInfoContainer}>
          <div className={styles.titleContainer}>
            {item?.title && <h2 className={styles.title}>{item?.title}</h2>}
            {item?.subtitle && <h3 className={styles.subtitle}>{item?.subtitle}</h3>}
          </div>
          {item?.discountValue && (
            <div className={styles.discountContainer}>
              <span className={styles.discountValue}>{item?.discountValue}</span>
              <span className={styles.discountDescription}>{item?.discountDescription}</span>
            </div>
          )}
        </div>
        {item?.description && <p className={styles.description}>{item?.description}</p>}
        <div className={styles.actions}>
          {item?.button && (
            <Button
              name={item?.button.title}
              ariaLabel={item?.button.title}
              className={styles.button}
              type={"primary"}
              text={item?.button.title}
              onClick={() => handleServiceButton(item?.button!)}
            />
          )}
          <div className={styles.addToWallet}>
            <AddToWalletButton
              className={styles.addToWalletButton}
              onClick={handleAppleWalletClick}
              type={WalletType.APPLE}
            />
          </div>
        </div>
        {item?.terms && <p className={styles.terms}>{item?.terms}</p>}
      </div>
    </section>
  );
};

export default SpecialBlockData;

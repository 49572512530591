"use client";

import { useState, FC, useCallback, useEffect, useMemo, useRef } from "react";
import cx from "classnames";
import Tabs from "../../common-components/tabs";
import { LeadData, LeadSection } from "../../../types/lead";
import { IPageBlock } from "../../../types/ui";
import styles from "./styles.module.scss";
import { addContactLead } from "../../../api/lead";
import { DropDownOption } from "../../../types/dropdown";
import Button from "../../common-components/button";
import Checkbox from "../../common-components/checkbox";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import { AnalyticsPageType, createAnalyticsDataFromVehicle, sendAnalyticsEvent } from "../../../utils/analitics";
import ContactFormWithCustomDropDown, {
  CUSTOM_DROPDOWN_FIELD_NAME,
} from "../../common-components/contact-form/contact-from-with-custom-dropdown";
import { getTranslationModule } from "../../../utils/translations";
import { smartFormat } from "../../../utils/stringFormatter";

interface ILeadGenerationFormData {
  title: string;
  text: string;
  initialTab: LeadSection;
  hideTextElements?: boolean;
  buttonTitle: string;
  hideTabs?: boolean;
  source?: string;
  dropDownOptions: string[];
  dropdownLabelText: string;
  dropdownRequired: boolean;
  defaultDropdownValue?: string;
}

const tabs = [LeadSection.SALES, LeadSection.SERVICE, LeadSection.PARTS, LeadSection.FINANCE];

const LeadGenerationForm: FC<IPageBlock> = ({ config, siteData, translations }) => {
  const t_common = getTranslationModule(translations, "common");
  const t_block = getTranslationModule(translations, "lead-generation-form");
  const leadConfig: ILeadGenerationFormData = config;
  const defaultDealer = siteData.dealerInfos[0];
  const dealerId = defaultDealer?.id;
  const contactOptions: DropDownOption[] = siteData.popupConfig.contactOptions;
  const currentContactOption = contactOptions[0].value;
  let initialTab = leadConfig?.initialTab ? tabs.indexOf(leadConfig?.initialTab) : 0;
  if (initialTab === -1) {
    initialTab = 0;
  }
  const [currentTab, setCurrentTab] = useState<number>(initialTab);
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const elementRef = useRef(null);

  const analyticsPageType = AnalyticsPageType.contact;
  const [formEngagementSent, setFormEngagementSent] = useState(false);
  const vehicleEventData = createAnalyticsDataFromVehicle();
  const formEventData = useMemo(() => {
    return {
      page_type: analyticsPageType,
      comm_type: "form",
      form_name: `contact_form_${tabs[currentTab]}`,
      form_type: "contact_form",
      department: tabs[currentTab].toLowerCase(),
      ...vehicleEventData,
    };
  }, [analyticsPageType, currentTab, vehicleEventData]);

  useEffect(() => {
    let newInitialTab = leadConfig?.initialTab ? tabs.indexOf(leadConfig?.initialTab) : 0;
    if (newInitialTab == -1) {
      newInitialTab = 0;
    }
    setCurrentTab(newInitialTab);
  }, [leadConfig?.initialTab]);

  const [formValidator, setFormValidator] = useState(
    new FormFieldValidator({
      firstName: "",
      lastName: "",
      contactType: currentContactOption,
      phone: "",
      email: "",
      comments: "",
      [CUSTOM_DROPDOWN_FIELD_NAME]: leadConfig.defaultDropdownValue,
    }),
  );

  useEffect(() => {
    const current = elementRef?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (!formEngagementSent) {
            sendAnalyticsEvent("asc_form_engagement", { comm_status: "start", ...formEventData });
            setFormEngagementSent(true);
          }
        }
      },
      { rootMargin: "0px" },
    );
    current && observer?.observe(current);

    return () => {
      current && observer.unobserve(current);
    };
  }, [formEngagementSent, formEventData]);

  const [disabled, setDisabled] = useState(false);

  const leadFormSource = leadConfig.source ?? tabs[currentTab];
  const dropdownOptions: DropDownOption[] = leadConfig.dropDownOptions.map((option) => ({
    text: option,
    value: option,
  }));

  const onSubmit = useCallback(() => {
    if (formValidator.validate() && isChecked) {
      sendAnalyticsEvent("asc_cta_interaction", {
        page_type: analyticsPageType,
        element_text: t_common("submit"),
        element_type: "form",
        event_action_result: "success",
        element_title: "submit",
        link_url: "",
      });
      sendAnalyticsEvent("asc_form_engagement", { comm_status: "crm_update", ...formEventData });

      setDisabled(true);
      setShowError(false);
      const { comments, ...otherLeadFormData } = formValidator.formData;
      const customDropdownValue = formValidator.formData[CUSTOM_DROPDOWN_FIELD_NAME];
      delete otherLeadFormData[CUSTOM_DROPDOWN_FIELD_NAME];
      const newComments = comments
        ? `${comments}, ${leadConfig.dropdownLabelText}: ${customDropdownValue}`
        : `${leadConfig.dropdownLabelText}: ${customDropdownValue}`;
      const leadData: LeadData = {
        ...otherLeadFormData,
        comments: newComments,
        source: leadFormSource,
        siteConfigId: siteData.siteConfigId,
        siteInstanceId: siteData.id,
      };

      addContactLead(dealerId, leadData).then(() => {
        sendAnalyticsEvent("asc_form_submission", {
          ...formEventData,
        });

        setTimeout(() => {
          setIsChecked(false);
          setFormValidator(
            new FormFieldValidator({
              firstName: "",
              lastName: "",
              contactType: currentContactOption,
              phone: "",
              email: "",
              comments: "",
            }),
          );
          setDisabled(false);
        }, 7000);
      });
    } else {
      if (!isChecked) {
        sendAnalyticsEvent("asc_cta_interaction", {
          page_type: analyticsPageType,
          element_text: t_common("submit"),
          element_type: "form",
          event_action_result: "error",
          element_title: "submit",
          link_url: "",
        });
        setShowError(true);
      }
    }
  }, [
    formValidator,
    isChecked,
    analyticsPageType,
    t_common,
    formEventData,
    leadConfig.dropdownLabelText,
    leadFormSource,
    siteData.siteConfigId,
    siteData.id,
    dealerId,
    currentContactOption,
  ]);

  return (
    <section className={styles.container}>
      {!leadConfig.hideTextElements && leadConfig.title && <h4 className={styles.title}>{leadConfig.title}</h4>}
      {!leadConfig.hideTextElements && leadConfig.text && <p className={styles.text}>{leadConfig.text}</p>}
      {!leadConfig.hideTabs && (
        <Tabs
          className={styles.tabs}
          items={[t_block("sales"), t_block("service"), t_block("parts"), t_block("finance")]}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          type={"big"}
        />
      )}
      <ContactFormWithCustomDropDown
        formValidator={formValidator}
        comment={true}
        disabled={disabled}
        contactOptions={contactOptions}
        formEventData={formEventData}
        dropDownOptions={dropdownOptions}
        dropdownLabelText={leadConfig.dropdownLabelText}
        dropdownRequired={leadConfig.dropdownRequired}
        textOnNonExistingValue={leadConfig.defaultDropdownValue}
        translations={translations}
      />
      <div className={styles.checkboxContainer}>
        {!disabled && (
          <Checkbox
            option={smartFormat(t_block("contact_us_checkbox"), { dealer: defaultDealer?.name })}
            onChanged={() => {
              setIsChecked(!isChecked);
              if (!isChecked === true) {
                setShowError(false);
              }
            }}
            checked={isChecked}
            disabled={disabled}
            onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
          />
        )}
        {showError && <p className={styles.errorMessage}>{t_common("check_boxes")}</p>}
      </div>
      {!disabled && (
        <div ref={elementRef}>
          <Button
            name={"contactMeButton"}
            type={"primary"}
            text={leadConfig.buttonTitle}
            className={styles.submitButton}
            onClick={onSubmit}
            ariaLabel={leadConfig.buttonTitle}
          />
        </div>
      )}
      {disabled && <p className={cx(styles.text, styles.textForm)}>{t_common("thank_you_lead")}</p>}
    </section>
  );
};

export default LeadGenerationForm;

"use client";

import { FC, useEffect } from "react";
import { AnalyticsPageType, fillAscDataLayer, sendAnalyticsEvent } from "../../../utils/analitics";
import { ModelCount } from "../../../types/ModelInfo";
import { Vehicle } from "../../../types/vehicle";

interface IPageAnalytics {
  analyticsPageType: AnalyticsPageType;
  locale: string;
  newModels: ModelCount[];
  createAscDataLayer?: boolean;
  vehicles: Vehicle[];
  dealerName: string;
}

const PageAnalytics: FC<IPageAnalytics> = ({
  newModels,
  createAscDataLayer,
  vehicles,
  dealerName,
  analyticsPageType,
  locale,
}) => {
  useEffect(() => {
    fillAscDataLayer(newModels, vehicles, analyticsPageType, locale, dealerName, createAscDataLayer);
    sendAnalyticsEvent("asc_pageview", { page_type: analyticsPageType, item_results: vehicles.length });
  }, [createAscDataLayer, dealerName, newModels, analyticsPageType, locale, vehicles]);
  return null;
};

export default PageAnalytics;

"use client";

import { FC, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import SearchDropdown from "@/ui/common-components/search-dropdown";
import Button from "../../common-components/button";
import {
  EnumerationFilterProperty,
  FilterProperty,
  FILTER_PROPERTY_TYPE_ENUM,
  FILTER_PROPERTY_TYPE_RANGE,
  RangeFilterProperty,
  VehicleFilters,
  FilterView,
} from "../../../utils/filterUtils";
import { DropDownOption } from "../../../types/dropdown";
import { SearchFilter } from "../../../types/filters";
import { useRouter } from "next/navigation";
import { getShortFilters } from "../../../api/filters";
import { FilterValue, IPageBlock } from "../../../types/ui";
import { createUrlFromPathAndQuery } from "../../../utils/ui";

interface FiltersData {
  filters: SearchFilter[];
  predefinedFilters: FilterValue[];
}

export enum SearchDataTheme {
  LIGHT = "light",
  PRIMARY = "primary",
}

interface SearchData {
  title: string;
  buttonTitle: string;
  filters: FiltersData;
  page?: string;
  filterView?: FilterView;
  theme?: SearchDataTheme;
}

function generateDropDownOptions(property: FilterProperty): DropDownOption[] {
  if (property.type === FILTER_PROPERTY_TYPE_ENUM) {
    const enumProperty = property as EnumerationFilterProperty;
    const result: DropDownOption[] = enumProperty.possibleValues.map((item) => ({
      value: item.value,
      text: item.text,
      active: item.selected,
    }));

    return result;
  } else if (property.type === FILTER_PROPERTY_TYPE_RANGE) {
    const rangeProperty = property as RangeFilterProperty;
    const result: DropDownOption[] = [];
    if (rangeProperty.minValue !== rangeProperty.maxValue) {
      result.push({
        value: "",
        text: `${rangeProperty.minValue} - ${rangeProperty.maxValue}`,
      });

      for (let i = rangeProperty.minValue; i <= rangeProperty.maxValue; i++) {
        result.push({
          value: i.toString(),
          text: i.toString(),
        });
      }
    } else {
      result.push({
        value: rangeProperty.minValue.toString(),
        text: `${rangeProperty.minValue}`,
      });
    }

    return result;
  }

  return [];
}

const SearchCarSection: FC<IPageBlock> = ({ config, siteData, pageContext }) => {
  const siteId = siteData.id;
  const searchInfoData: SearchData = config;
  const filters: SearchFilter[] = searchInfoData.filters.filters;
  const [searchFilters, setSearchFilters] = useState<SearchFilter[]>(filters);
  const [previousVehicleFilters, setPreviousVehicleFilters] = useState<VehicleFilters>(new VehicleFilters(filters));
  const [filterParams, setFilterParams] = useState<any>(() => {
    const cleanFilters: any = {};
    searchInfoData.filters.predefinedFilters.forEach((item) => {
      cleanFilters[item.filter] = item.value;
    });
    return cleanFilters;
  });
  const router = useRouter();
  const page = searchInfoData.page || "/search-result-page/all";

  const vehicleFilters = useMemo(() => {
    const processedFilters = new VehicleFilters(searchFilters);
    const resultFilters = new VehicleFilters([]);
    resultFilters.filterProperties = previousVehicleFilters.filterProperties.map((item) => {
      const newFilterProperty = processedFilters.filterProperties.find(
        (newFilter) => newFilter.propertyName === item.propertyName,
      );
      if (newFilterProperty) {
        return newFilterProperty;
      }
      if (filterParams[item.propertyName] !== undefined) {
        return item;
      }
      return item.createEmpty();
    });
    resultFilters.updateWithQuery(filterParams);
    return resultFilters;
  }, [filterParams, previousVehicleFilters, searchFilters]);

  const changedCallback = useCallback(
    (value: string, tag?: string) => {
      let newFilterParams = { ...filterParams };
      const propertyName = tag!;
      const propertyIndex = vehicleFilters.filterProperties.findIndex((item) => item.propertyName === propertyName);
      for (let i = propertyIndex + 1; i < vehicleFilters.filterProperties.length; i++) {
        delete newFilterParams[vehicleFilters.filterProperties[i].propertyName];
        vehicleFilters.filterProperties[i].reset();
      }

      if (value) {
        newFilterParams[propertyName] = value;
      } else {
        delete newFilterParams[propertyName];
      }
      setPreviousVehicleFilters(vehicleFilters);
      setFilterParams(newFilterParams);
      const initialFilters = searchInfoData.filters.filters.map((item) => item.name.value);
      getShortFilters(siteId, initialFilters, newFilterParams, pageContext.locale).then((response) => {
        if (previousVehicleFilters.isEmpty()) {
          setPreviousVehicleFilters(new VehicleFilters(response || []));
        }
        setSearchFilters(response || []);
      });
    },
    [filterParams, previousVehicleFilters, pageContext.locale, searchInfoData.filters.filters, siteId, vehicleFilters],
  );

  const searchCallback = useCallback(() => {
    const url = createUrlFromPathAndQuery(page, vehicleFilters.getQuery());
    router.push(url);
  }, [page, router, vehicleFilters]);

  return searchInfoData ? (
    <section
      className={cx(styles.search, {
        [styles.light]: searchInfoData?.theme === SearchDataTheme.LIGHT,
        [styles.primary]: searchInfoData?.theme === SearchDataTheme.PRIMARY,
      })}
    >
      <h3 title={searchInfoData?.title} className={styles.searchTitle}>
        {searchInfoData?.title}
      </h3>
      <div
        className={cx(styles.searchPanel, {
          [styles.searchPanelRow]: searchInfoData?.filterView === FilterView.ROW,
        })}
      >
        {vehicleFilters.filterProperties.map((item, index) => (
          <SearchDropdown
            options={generateDropDownOptions(item)}
            selectedOption={filterParams[item.propertyName]}
            onChanged={changedCallback}
            key={item.propertyName}
            tag={item.propertyName}
            label={item.title}
            rowView={(index === 0 || index === 1) && searchInfoData?.filterView === FilterView.ROW ? true : false}
          />
        ))}
        <Button
          name={searchInfoData?.buttonTitle}
          ariaLabel={searchInfoData?.buttonTitle}
          className={styles.searchButton}
          text={searchInfoData?.buttonTitle}
          onClick={searchCallback}
          type="primary"
        />
      </div>
    </section>
  ) : null;
};

export default SearchCarSection;

"use client";
import { useState, FC } from "react";
import styles from "./section.module.scss";
import SpecialCard from "./card";
import { IPageBlock } from "../../../types/ui";
import { ISpecialCardNewSection } from "../../../types/service";
import Popup from "./popup";

const SpecialSection: FC<IPageBlock> = ({ config, siteData, pageContext, translations }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentCard, setCurrentCard] = useState<number>(0);

  const specialSectionConfig: ISpecialCardNewSection = config;

  const dealerPhoto = !siteData?.noImagesPhoto ? "" : siteData?.baseUrl + siteData?.noImagesPhoto;

  const handleClose = (e: React.UIEvent) => {
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <section className={styles.section}>
      <div className={styles.sectionContainer}>
        {specialSectionConfig?.specials?.map((special, index: number) => (
          <SpecialCard
            key={index}
            data={special}
            className={styles.card}
            baseUrl={siteData.baseUrl}
            isMobile={pageContext.isSmartphone}
            onClick={() => {
              setCurrentCard(index);
              setIsOpen(true);
            }}
            dealerPhoto={dealerPhoto}
            siteData={siteData}
            translations={translations}
          />
        ))}
      </div>
      {specialSectionConfig?.specials?.length > 0 && (
        <Popup
          isOpen={isOpen}
          handleClose={handleClose}
          data={specialSectionConfig?.specials[currentCard || 0]}
          isMobile={pageContext.isSmartphone}
          baseUrl={siteData.baseUrl}
          siteData={siteData}
          pageContext={pageContext}
          translations={translations}
        />
      )}
    </section>
  );
};

export default SpecialSection;

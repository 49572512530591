import { postDataRaw } from "./base";

export interface SpecialCouponData {
  specialValue: string;
  specialDescription: string;
  barcodeMessage?: string;
  expires?: string;
  description?: string;
  shortDescription?: string;
  terms?: string;
}

export async function createGoogleWalletSpecials(siteId: string, couponData: SpecialCouponData): Promise<string> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/specialBonusPass?type=android`;
  const result = await postDataRaw(API, couponData);

  return new TextDecoder().decode(result);
}

export async function createAppleWalletSpecials(siteId: string, couponData: SpecialCouponData): Promise<ArrayBuffer> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/specialBonusPass?type=ios`;
  const result = await postDataRaw(API, couponData);

  return result;
}
